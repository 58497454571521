// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

$line-height-base: 1.8;

$font-family-sans-serif:      "Nunito","游ゴシック", "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$display3-size: 3.7rem;

// Color Scheme
// Change the hex values below to alter the main color scheme.

$primary:       #4361ee;
$primary-2:     #023e8a;
$primary-3:     #ff686b;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);
