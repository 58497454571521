//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.dropdown-grid-menu {
  font-size: 0.9rem;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .navbar-brand > img {
    height: 38px;
  }
}

@media screen and (min-width: 768px) {
  .navbar-brand > img {
    height: 100px;
  }
}
